import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class TenantsService {
  constructor(private http: HttpClient) { }

  getTenantInfo(tenantId: number): Observable<any> {
    const tenantDataUrl = 'https://tenantsapi.symplast.com/tenants/' + tenantId.toString() + '/publicSettings';
    return this.http.get<any>(tenantDataUrl);
  }
}
