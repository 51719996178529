import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TenantsService } from './tenants.service';

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.css']
})
export class LandingPageComponent implements OnInit {
  public appStoreLink = 'https://apps.apple.com/app/symplast-patient/id1227630932?mt=8';
  // tslint:disable-next-line: max-line-length
  public playMarketLink = 'https://play.google.com/store/apps/details?id=com.symplast.patient&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1';
  public practiceLogoUrl = '';

  constructor(
    public tenantsService: TenantsService, private activatedRoute: ActivatedRoute) { }
  
  ngOnInit(): void {
    const userAgent = navigator.userAgent || navigator.vendor;

    this.activatedRoute.params.subscribe(params => {      
      this.LoadTenantData((params['id']));
    });    

    if (userAgent.match(/iPhone/i) || userAgent.match(/iPod/i) || userAgent.match(/iPad/i)) {
      setTimeout(() => {
        location.href = this.appStoreLink;
      }, 5000);
    }

    // Android Version:
    if (userAgent.match(/android/i)) {
      setTimeout(() => {
        // tslint:disable-next-line: max-line-length
        location.href = this.playMarketLink;
      }, 5000);
    }
  }
  
private LoadTenantData(code: any) {
  if (code != null && code !== '') {
    this.tenantsService.getTenantInfo(code).subscribe(tenant => {
      if (tenant.result != null) {
        this.practiceLogoUrl = tenant.result.logoUrl;        
      } else {
        this.practiceLogoUrl = 'assets/images/fallback-logo-image.JPG';
      }
    });
  }
}
}