import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LandingPageComponent} from './landingpage.component';


const routes: Routes = [
  { path: ':id', redirectTo: '/landingPage/:id', pathMatch: 'full' },  
  { path: 'landingPage/:id', component: LandingPageComponent },
  { path: '**', component: LandingPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
