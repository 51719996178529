import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    // tslint:disable-next-line:pipe-naming
    name: 'firstLetter'
})
export class FirstLetterPipe implements PipeTransform {
    public transform(value: string, args: any[]): string | boolean {
        if (value === null || value === 'your practice name') { return 'first letters of your practice name'; }
        return value.split(' ')[0];
    }
}
